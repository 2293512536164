<template>
  <div>
    <div class="image_warp"
         @mouseleave="mouseover">
      <img :src="imageList[index]"
           v-drag
           :width="imgWidth?`${imgWidth * scale}rem`:''"
           :style="{
           'top':`${scale*120}px`,
           'left':`${scale*120}px`,
           'right':`${scale*120}px`,
           'bottom':`${scale*120}px`,
           'width':imgWidth?`${imgWidth * scale}px`:''
           }"
           class="img_warp_Sss">
    </div>

    <div class="operyion_warp"
         v-if="imageList.length">
      <span @click="refresh">
        <img src="@/assets/checkTestPage/icon-shuaxin@2x.png"
             alt="">
      </span>
      <span @click="xiao">
        <img src="@/assets/checkTestPage/icon-xiao@2x.png"
             alt="">
      </span>
      <span @click="da">
        <img src="@/assets/checkTestPage/icon-da@2x.png"
             alt="">
      </span>
    </div>
    <div class="image_list">
      <img v-for="(item,idx) in imageList"
           :key="idx"
           @click="showImg(idx)"
           :src="imageList[idx]"
           :class="{'item':true,'isActive':idx == index}"
           alt="">
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data () {
    return {
      // 计算第几张图片
      index: 0,
      // 计算角度
      curent: 0,
      suoIndex: 1,
      scale: 1,
      imgWidth: 0,
      imgHeight: 0,
      active: false
    }
  },
  props: ['imageList'],
  watch: {
    index () {
      this.curent = 0
      document.getElementsByTagName('img')[0].style.transform = ''
    }
  },
  directives: {
    drag: {
      // 指令的定义
      inserted: function (el) {
        // el.drag();
        // console.log(el);
        //获取元素
        // var dv = document.getElementById("dv");
        let x = 0;
        let y = 0;
        let l = 0;
        let t = 0;
        Vue.prototype.$isDown = false;
        //鼠标按下事件
        el.onmousedown = function (e) {
          e.preventDefault()
          e.stopPropagation()
          //获取x坐标和y坐标
          x = e.clientX;
          y = e.clientY;

          //获取左部和顶部的偏移量
          l = el.offsetLeft;
          t = el.offsetTop;
          //开关打开
          Vue.prototype.$isDown = true;
          //设置样式
          el.style.cursor = "move";
        };
        //鼠标移动
        window.onmousemove = function (e) {
          if (Vue.prototype.$isDown == false) {
            return;
          }
          //获取x和y
          let nx = e.clientX;
          let ny = e.clientY;
          //计算移动后的左偏移量和顶部的偏移量
          let nl = nx - (x - l);
          let nt = ny - (y - t);

          el.style.left = nl + "rem";
          el.style.top = nt + "rem";
        };
        //鼠标抬起事件
        el.onmouseup = function () {
          //开关关闭
          Vue.prototype.$isDown = false;
          el.style.cursor = "default";
        };
      }
    }
  },
  mounted () {
    this.imgWidth = document.getElementsByClassName('img_warp_Sss')[0].clientWidth
    this.imgHeight = document.getElementsByClassName('img_warp_Sss')[0].clientHeight
  },
  methods: {
    refresh () {
      this.curent += 90
      var oimg = document.getElementsByClassName('img_warp_Sss')[0]
      oimg.style.transform = `rotate(${this.curent}deg)`
    },
    showImg (idx) {
      this.index = idx
    },
    xiao () {
      if (!this.imgWidth) {
        this.imgWidth = document.getElementsByClassName('img_warp_Sss')[0].clientWidth
      }
      this.scale -= 0.2
      if (this.scale <= 0.2) {
        this.scale = 0.2
      }
    },
    da () {
      if (!this.imgWidth) {
        this.imgWidth = document.getElementsByClassName('img_warp_Sss')[0].clientWidth
      }
      this.scale += 0.2
    },
    setWidth () {
    },
    mouseover (el) {
      Vue.prototype.$isDown = false
    }
  }
}
</script>

<style lang="scss" scoped>
// div {
//   ::-webkit-scrollbar {
//     display: none; /* Chrome Safari */
//   }
//   scrollbar-width: none; /* Firefox */
// }
.img_warp {
  width: 100%;
  height: 700rem;
  display: flex;
  overflow: auto;

  img {
    width: 700rem;
  }
}
.img_selector {
  width: 500rem;
  margin: 0 auto;
}
.optration {
  margin-top: 15rem;
  width: 100%;
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    cursor: pointer;
    margin: 0 5rem;
    font-size: 20rem;
  }
}

::v-deep .mouse-cover {
  height: 100rem !important  ;
}
.image_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15rem;
  img {
    width: 100rem;
    height: 100rem;
    margin: 5rem;
    margin-left: 0;
    cursor: pointer;
    border: 1rem solid grey;
  }
}
.isActive {
  border: 4rem solid #3f9eff !important;
}
.operyion_warp {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3f9eff;
  border-radius: 5rem;
  width: 160rem;
  height: 44rem;
  margin: 10rem auto;
  background: #2d2d2d;
  span {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 24rem;
      height: 24rem;
      cursor: pointer;
    }
  }
}
img {
  border-radius: 5rem;
}
.image_warp {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: auto;
  height: 680rem;
  max-height: calc(100vh - 290rem);
  width: 100%;
}
.img_warp_Sss {
  text-align: center;
  transform: rotate(0);
  transition: all 0.3s;
  position: absolute;
  -webkit-user-drag: none;
}
</style>