<template>
  <div class="self_correct">
    <div class="top">
      <TopCard ref="TopCardRef"
               text="批改试卷"
               :showBack="true" />
    </div>
    <div class="fixed_btn">
      <!-- <div @click="change(-1)">上一题</div>
      <div @click="change(1)">下一题</div> -->
      <img src="@/assets/self_correct/icon-答题卡@2x.png"
           @click="toCard"
           alt="">
    </div>
    <div class="wrap">
      <div class="left_image">
        <PriviewImage ref="PriviewImageRef"
                      :imageList="Object.values(info.images).flat()" />
      </div>
      <div class="right_controller">
        <RightOpertion ref="RightOpertionRef"
                       :question.sync="info.volist[this.index]" />
      </div>
    </div>
    <CommenToUser ref="CommenToUserRef" />
  </div>
</template>

<script>
import TopCard from '@/views/components/top_card/index.vue'
import PriviewImage from './components/image.vue'
import RightOpertion from './components/opertion.vue'
import { getAnalysis, submitUserPaper } from '@/api/my_paper.js'
import CommenToUser from './components/comment.vue'
export default {
  data () {
    return {
      index: 0,
      info: {
        images: [],
        volist: [],
      }
    }
  },
  components: {
    TopCard, PriviewImage, RightOpertion, CommenToUser
  },
  mounted () {
    if (window.sessionStorage.getItem('analysis_paper')) {
      this.info = JSON.parse(window.sessionStorage.getItem('analysis_paper'))
      this.index = Number(window.sessionStorage.getItem('analysis_index'))
    } else {
      this.getPaper()
    }
  },
  methods: {
    change (num) {
      if (num < 0 && this.index == 0) {
        return this.$message.warning('已经是第一题')
      } else if (this.index == this.info.volist.length - 1 && num > 0) {
        return this.$message.warning('已经是最后一题')
      }
      this.index += num
    },
    async getPaper () {
      const { data } = await getAnalysis({ user_paper_id: this.$route.query.paper_id })
      this.info = data
    },
    toCard () {
      window.sessionStorage.setItem('analysis_index', this.index)
      this.info.user_paper_id = this.$route.query.paper_id
      window.sessionStorage.setItem('analysis_paper', JSON.stringify(this.info))
      this.$router.push('/over_card?type=self_correct')
    },
    submitAll () {
      let data = {
        user_paper_id: this.$route.query.paper_id,
        user_paper_comment: this.info.user_paper_comment,
        compact: []
      }

      // console.log('this.info.volist', this.info.volist)
      this.info.volist.forEach(item => {
        if (item.auto_judge != 1) {
          data.compact.push({
            user_paper_detail_id: item.user_paper_detail_id,
            comment_to_user: item.comment_to_user,
            user_score: item.user_score,
          })
        }
      });
      this.$refs.CommenToUserRef.form = data
      this.$refs.CommenToUserRef.dialogVisible = true
    },
    async postUserPaper (data) {
      await submitUserPaper(data)
      this.$router.go(-1)
      this.$message.success('提交成功')
    }
  }
}
</script>

<style lang="scss" scoped>
.self_correct {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #f6f6f6;
  .top {
    width: 100vw;
    height: 100rem;
  }
  .wrap {
    height: calc(100vh - 100rem);
    width: calc(100vw - 50rem);
    overflow: hidden;
    display: flex;
    margin-left: 50rem;
    justify-content: space-between;
    .left_image {
      margin-right: 50rem;
      flex: 1;
    }
    .right_controller {
      flex: 1;
      height: calc(100vh - 100rem);
      background: white;
      min-width: 0; //兼容在Firefox flex:1不起作用
    }
  }
}

.fixed_btn {
  position: fixed;
  top: 50rem;
  right: 30rem;
  font-size: 24rem;
  display: flex;
  // width: 350rem;
  div {
    flex: 1;
    text-align: center;
  }
  img {
    width: 36rem;
    height: 36rem;
  }
}
</style>